import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/layout";
import SubPage from "./comps/SubPage";
  
const Pintura = () => {
  return (
    <Layout infoPage={{page: "tecnologia", sub: "pintura"}}>
      <SubPage name="Pintura" />
    </Layout>
  )
}

export default Pintura;